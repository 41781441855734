const tableGrid = [
  'w-2/24',
  'w-6/24',
  'w-2/24',
  'w-5/24',
  'w-3/24',
  'w-6/24',
  'w-2/24',
  'w-2/24'
];

export default tableGrid;
